/* ready */
$(function () {
  'use strict';

  animation($('.c-animate, .c-fade--up'));
  $('.p-top-news.c-fade--up').addClass('is-animate');

  // client-slider
  $('.p-top-client__slide').slick({
    autoplay: true,
    slidesToShow: 5,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  });
});


/* scroll */
$(window).on('scroll', function () {
  animation($('.c-animate, .c-fade--up'));
});


/* function */
function animation(elm) {
  elm.each(function () {
    var basePosition = $(this).offset().top;
    var scrollPosition = $(window).scrollTop();
    var deviceHeight = $(window).height();
    if (scrollPosition > basePosition - deviceHeight * .7) {
      $(this).addClass('is-animate');
    }
  });
}