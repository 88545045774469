/* ready */
$(function () {
  'use strict';

  /* スムーススクロール */
  $('a[href^="#"]').on('click', function () {
    // スピード（ミリ秒）
    var speed = 300;
    // アンカーの値取得
    var href = $(this).attr('href');
    // 移動先を取得
    var target = $(href == "#" || href == "" ? 'html' : href);
    // 移動先を座標で取得
    var position;
    if ($(this).parent().hasClass('l-pagetop')) {
      position = target.offset().top;
    } else {
      position = target.offset().top - $('.l-header').outerHeight();
    }
    // アニメーション
    $('body,html').not(':animated').animate({ scrollTop: position }, speed, 'swing');
    return false;
  });

  // slider
  $('.c-list--works--slide').slick({
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 2,
          centerPadding: "15%"
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          centerMode: true
        }
      }
    ]
  });

  // gnaviCurrent
  gnaviCurrent($('.l-gnavi ul li'));

  // naviCurrent
  naviCurrent($('.c-list--link li, .c-list--service li'));

  // gnavi active
  $('.l-gnavi > ul > li').on({
    'mouseenter': function () {
      $('.l-header').addClass('is-active');
    },
    'mouseleave': function () {
      $('.l-header').removeClass('is-active');
    }
  });

  // megamenu
  $('.l-mega__par > li').on('mouseenter', function () {
    let index = $(this).parent().children('li').index(this);

    $(this).addClass('is-select').siblings().removeClass('is-select');
    $(this).closest('.l-mega').find('.l-mega__chd > ul').removeClass('is-select');
    $(this).closest('.l-mega').find('.l-mega__chd > ul').eq(index).addClass('is-select');
  });

  // spnavi
  $('.l-header__menu').on('click', function () {
    $(this).toggleClass('is-open');
    $('.l-spnavi').stop().fadeToggle();
  });
  $('.l-spnavi nav > ul li span').on('click', function () {
    let data_navi = $(this).parent().attr('data-navi');

    $(this).parent().addClass('is-select').siblings().removeClass('is-select');
    $('.l-spnavi__gt').find('div[data-navi=' + data_navi + ']').addClass('is-select').siblings().removeClass('is-select');
  });

  // fnaviAccordion
  $('.l-footer__nav__acc-ttl span').on('click', function () {
    if ($(this).parent().hasClass('l-footer__nav__acc-ttl--service')) {
      $(this).parent().toggleClass('is-open').next('ul').stop().slideToggle();
      $(this).closest('div').next().find('ul').stop().slideToggle();
    } else {
      $(this).parent().toggleClass('is-open').next('ul').stop().slideToggle();
    }
  });

  // accordion
  accordion($('.c-list--faq dt'));

  // floatingBanner
  floatingBanner($('.l-flt-bnr'), $('.l-flt-bnr__close'));
});

/* load */
$(window).on('load', function () {
  // 外部アンカーリンク
  var linkOffset = $('.l-header').outerHeight();
  var url = $(location).attr('href');

  if (url.indexOf('id=') != -1) {
    var id = url.split('id=');
    var target = $('#' + id[id.length - 1]);
    if (target.length) {
      var position = target.offset().top - linkOffset;
      $('html, body').animate({ scrollTop: position }, 0);
    }
  }
});

/* scroll */
$(window).on('scroll', function () {
  const header = $('.l-header');
  const pagetopBtn = $('.l-pagetop');
  const footerBtn = $('.l-footer__consultation');
  const floatingBanner = $('.l-flt-bnr');

  if ($(this).scrollTop() > 500) {
    header.addClass('is-fixed');
    pagetopBtn.fadeIn();
    footerBtn.fadeIn();

  } else {
    header.removeClass('is-fixed');
    pagetopBtn.fadeOut();
    footerBtn.fadeOut();
  }

  if ($(this).scrollTop() > 100) {
    if (document.cookie.indexOf('floating') === -1) {
      floatingBanner.fadeIn();
    }
  }
});


function gnaviCurrent(naviElm) {
  const currentUrl = location.pathname + location.hash;

  naviElm.each(function () {
    let link = $(this).children('a').attr('href');

    if (link == '/') {
      var matchLink = new RegExp('^' + link + '$');
    } else {
      var matchLink = new RegExp('^' + link);
    }

    if (link == currentUrl || currentUrl.match(matchLink)) {
      $(this).addClass('is-current');
    }
  });
}

function naviCurrent(naviElm) {
  const currentUrl = location.pathname + location.hash;

  naviElm.each(function () {
    let link = $(this).children('a').attr('href');

    var matchLink = new RegExp('^' + link + '$');

    if (link == currentUrl || currentUrl.match(matchLink)) {
      $(this).addClass('is-current');
    }
  });
}

function accordion(accElm) {
  $(accElm).on('click', function () {
    $(this).toggleClass('is-open').next().stop().slideToggle();
  });
}

function floatingBanner(banner, closeButton) {
  // document.cookie = "floating=false; max-age=0";
  closeButton.click(function () {
    banner.hide();
    // const maxAge = 10; // 10秒（テスト用）;
    const maxAge = 60 * 60 * 24 * 7; // 期限は7日間;
    document.cookie = 'floating=false; max-age=' + maxAge + '; path=/';
  });
}

// particles.js
const particles = document.getElementById("particles-js");
if (particles) {
  particlesJS("particles-js", { "particles": { "number": { "value": 80, "density": { "enable": true, "value_area": 800 } }, "color": { "value": "#1974d2" }, "shape": { "type": "circle", "stroke": { "width": 0, "color": "#000000" }, "polygon": { "nb_sides": 5 }, "image": { "src": "img/github.svg", "width": 100, "height": 100 } }, "opacity": { "value": 0.5, "random": false, "anim": { "enable": false, "speed": 1, "opacity_min": 0.1, "sync": false } }, "size": { "value": 3, "random": true, "anim": { "enable": false, "speed": 40, "size_min": 0.1, "sync": false } }, "line_linked": { "enable": true, "distance": 150, "color": "#1974d2", "opacity": 0.4, "width": 1 }, "move": { "enable": true, "speed": 6, "direction": "none", "random": false, "straight": false, "out_mode": "out", "bounce": false, "attract": { "enable": false, "rotateX": 600, "rotateY": 1200 } } }, "interactivity": { "detect_on": "canvas", "events": { "onhover": { "enable": true, "mode": "repulse" }, "onclick": { "enable": true, "mode": "push" }, "resize": true }, "modes": { "grab": { "distance": 400, "line_linked": { "opacity": 1 } }, "bubble": { "distance": 400, "size": 40, "duration": 2, "opacity": 8, "speed": 3 }, "repulse": { "distance": 200, "duration": 0.4 }, "push": { "particles_nb": 4 }, "remove": { "particles_nb": 2 } } }, "retina_detect": true });
}